import axios from "axios";

export class ApiBase {
    defaultConfigMap = config => config;

    constructor(url, mapConfig) {
        mapConfig = mapConfig ?? this.defaultConfigMap;
        this._mapConfig = mapConfig;
        this._url = url;
    }

    get(uri, config = {}) {
        return axios.get(
            this._url + uri,
            this._mapConfig(config)
        );
    }

    post(uri, data = {}, config = {}) {
        return axios.post(
            this._url + uri,
            data,
            this._mapConfig(config)
        );
    }

    put(uri, data = {}, config = {}) {
        return axios.put(
            this._url + uri,
            data,
            this._mapConfig(config)
        );
    }

    patch(uri, data = {}, config = {}) {
        return axios.patch(
            this._url + uri,
            data,
            this._mapConfig(config)
        );
    }

    delete(uri, config={}) {
        return axios.delete(
            this._url + uri,
            this._mapConfig(config)
        );
    }
}
