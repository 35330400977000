import {Layout, Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import {useState} from "react";

export function Sider({options, storageKey='common-layout-sider-collapsed'}) {
    const [collapsed, setCollapsed] = useState(localStorage.getItem(storageKey) === 'true');
    const location = useLocation();

    const handleCollapse = value => {
        setCollapsed(value);
        localStorage.setItem(storageKey, value);
    }

    const selectedMenuItem = location.pathname.split('/')[1]
    return (
        <Layout.Sider
            collapsible
            theme={'light'}
            collapsed={collapsed}
            onCollapse={handleCollapse}
        >
            <Menu mode="inline" selectedKeys={[selectedMenuItem]}>
                {options.map(x => (
                    <Menu.Item icon={x.icon} key={x.key}>
                        <Link to={x.link}>
                            {x.name}
                        </Link>
                    </Menu.Item>
                ))}
            </Menu>
        </Layout.Sider>
    );
}
