import React, {useEffect, useState} from "react";
import RepApi from "../../services/RepApi";
import {FullHeightCard} from "../../components/cards";
import {Descriptions, Divider, message, Popconfirm, Space, Spin, Tag, Tooltip} from "antd";
import {ArrowLeftOutlinedButton, DeleteOutlinedButton, EditFilledButton} from "../../components/buttons/SimpleButtons";
import {useHistory} from "react-router-dom";
import {EditTemplateModal} from "../templates/EditTemplateModal";
import {JsonEditor} from "jsoneditor-react";
import {DownloadButton} from "../../components/buttons/DownloadButton";

function useTemplateData(id) {
    const [data, setData] = useState(null);
    useEffect(() => {
        RepApi.get(`/templates/${id}`).then(
            templatesResponse => {
                RepApi.get('/datasources/').then(datasourcesResponse => {
                    const template = templatesResponse.data;
                    const datasources = datasourcesResponse.data;
                    datasources.findById = function (id) {
                        return this.find(x => x.id === id);
                    }
                    setData({
                        ...template,
                        datasources: template.datasources.map((dsId, idx) => {
                            const dsData = datasources.findById(dsId) ?? {
                                name: 'UNKNOWN',
                                id: `UNKNOWN_${idx}`
                            };
                            return {...dsData};
                        })
                    })
                })
            }
        )
    }, [id]);
    return data;
}

export function Template({id}) {
    const [editModalVisible, setEditModalVisible] = useState(false);
    const data = useTemplateData(id);
    const history = useHistory();

    const deleteTemplate = () => {
        RepApi.delete(`/templates/${id}`).then(_ => {
            history.push("/templates");
            message.success("Deleted");
        });
    }

    const enterEditMode = () => {
        setEditModalVisible(true);
    }

    return (
        <>
            <EditTemplateModal
                title={'Edit Template'}
                notebookProps={{
                    required: false,
                    tooltip: "Choose a file in order to overwrite the existing one. Leaving field as is does not change the stored file."
                }}
                data={{
                    ...data,
                    datasources: (data?.datasources ?? []).map(x => x.id)
                }}
                visible={editModalVisible}
                updateVisibility={setEditModalVisible}
                okText={"Edit"}
                onOk={(form, values) => {
                    const uri = `/templates/${id}`;
                    let promise;
                    if (values.notebook == null) {
                        form.delete("notebook");
                        promise = RepApi.patch(uri, form);
                    } else {
                        promise = RepApi.put(uri, form);
                    }
                    return promise.then(_ => {
                        history.replace(uri);
                        history.go();
                    });
                }}
            />
            <FullHeightCard
                title={(
                    <Space>
                        <ArrowLeftOutlinedButton
                            type={"circle"}
                            style={{marginRight: '8px'}}
                            size={"small"}
                            onClick={_ => history.push("/templates")}
                        />
                        Template {data?.title}
                    </Space>
                )}
                extra={[
                    <Tooltip title={"Edit"} key={"edit"}>
                        <EditFilledButton type={"link"} onClick={enterEditMode}/>
                    </Tooltip>,
                    <Tooltip title={"Delete"} key={"delete"}>
                        <Popconfirm title={"Are you sure to delete the template?"} onConfirm={deleteTemplate}>
                            <DeleteOutlinedButton type={"link"} iconProps={{style: {color: 'red'}}}/>
                        </Popconfirm>
                    </Tooltip>
                ]}
            >
                <Spin spinning={data == null} style={{height: '100%'}}>
                    <Descriptions column={1}>
                        <Descriptions.Item label={"Description"}>
                            {data?.description}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Data Sources"}>
                            {(data?.datasources ?? []).map(x => (
                                <Tag key={x.id}>{x.name}</Tag>
                            ))}
                        </Descriptions.Item>
                    </Descriptions>
                    <DownloadButton url={data?.notebook} title="Download Notebook"/>
                    <Divider/>
                    {data?.params_schema && (
                        <JsonEditor name="params_schema" value={data?.params_schema ?? {}} mode={"view"}/>
                    )}
                    <Divider/>
                    {data?.params_ui_schema && (
                        <JsonEditor name="params_ui_schema" value={data?.params_ui_schema ?? {}} mode={"view"}/>
                    )}
                </Spin>
            </FullHeightCard>
        </>
    );
}
