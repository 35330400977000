import {Layout, Row, Space} from "antd";
import {About, CurrentUserAvatar, HomeLink, LogOut, OtherResources} from "@digatex/digatex-ui-lib";

export function Header() {
    return (
        <Layout.Header style={{padding: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <HomeLink/>
            <div style={{marginLeft: 'auto'}}>
                <Row>
                    <Space size={"middle"}>
                        <About/>
                        <OtherResources/>
                        <LogOut />
                        <CurrentUserAvatar/>
                    </Space>
                </Row>
            </div>
        </Layout.Header>
    )
}

