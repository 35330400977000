export function empty() {

}


export function lastElementOf(arr) {
    return arr[arr.length - 1];
}


export function withoutDuplicates(origin) {
    return [...new Set(origin)];
}


export function repApiAdaptedPageData(data) {
    const results = [...data.results];
    if (data.next) {
        results.length += 1;
    }
    return results;
}
