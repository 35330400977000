import {Theme as AntDTheme} from "@rjsf/antd";
import {RemoteSelect} from "./select/RemoteSelect";
import React from "react";
import {withTheme} from "@rjsf/core";

const RemoteSelectWidget = props => {
    const {options} = props;
    return (
        <RemoteSelect {...options} onSelect={selected => props.onChange(selected)}/>
    );
};

const customWidgets = {
    remoteSelect: RemoteSelectWidget
};

export const RJSForm = withTheme({...AntDTheme, widgets: {...AntDTheme.widgets, ...customWidgets}});