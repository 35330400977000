import {
    AnonymousExtractedAttribute,
    ComposedExtractedAttribute,
    FilterableFormItem,
    RuleSingleValue,
    ExtractedText,
    RuleRequired,
} from "../../components/forms";
import {AnonymousComponent, BaseAssignClear} from "../../components/Misc";
import React, {Fragment, useCallback} from "react";
import RepApi from "../../services/RepApi";
import {SelectIdTableWithSearch, WithManagedDataSource} from "../../components/tables";
import {Collapse, Form, message, Modal} from "antd";
import validator from "@rjsf/validator-ajv8";
import {GenericEntityFormModal} from "../../components/modals";
import {repApiAdaptedPageData} from "../../utils";
import {RJSForm} from "../../components/forms/rjsf";


export function mapApiTemplateModelToCreateModel(x) {
    return {
        id: x?.id,
        title: x?.title,
        parameters: x?.parameters ?? {},
        schema: x?.params_schema ?? {},
        ui_schema: x?.params_ui_schema ?? {},
    }
}

export const emptyCreateReportModel = {
    title: 'Untitled Report',
    template: {
        id: null,
        title: null,
        parameters: {},
        schema: {},
        ui_schema: {}
    }
}

const requiredRules = subject => [RuleRequired(subject)];
export const extractedReportAttributes = new ComposedExtractedAttribute([
    new ExtractedText('title', 'Title', {rules: requiredRules('Title')}),
    new AnonymousExtractedAttribute(model => [{
        path: 'template',
        value: model.template,
        toRequestValues: values => ({
            template: values.template.id,
            parameters: values.template.parameters
        }),
        toFormItem: () => (
            <Fragment key={"template"}>
                <FilterableFormItem
                    required
                    name={"template"}
                    label={"Template"}
                    key={"template"}
                    rules={[
                        RuleSingleValue(
                            value => value.id != null,
                            'Template is required'
                        )
                    ]}
                >
                    <AnonymousComponent>
                        {({value, onChange}) => (
                            <BaseAssignClear
                                onChange={x => onChange(mapApiTemplateModelToCreateModel(x))}
                                value={value.title}
                                assignTitle={"Assign Template"}
                                clearTitle={"Clear Template"}
                                modalRender={({visible, closeModal, handleSelect}) => (
                                    <TemplateSelectModal
                                        visible={visible}
                                        onCancel={closeModal}
                                        onSelect={handleSelect}
                                    />
                                )}
                            />
                        )}
                    </AnonymousComponent>
                </FilterableFormItem>

                <Form.Item name={["template"]} noStyle shouldUpdate={true}>
                    <ParametersFormList/>
                </Form.Item>
            </Fragment>
        )
    }])
]);


export const emptyReportAttributes = async () => {
    return extractedReportAttributes.extract(emptyCreateReportModel);
}


export function CreateReportModal({visible, updateVisibility, onCreate, attributes}) {
    return (
        <GenericEntityFormModal
            visible={visible}
            updateVisibility={updateVisibility}
            title={"Create Report"}
            onFinish={async values => {
                return RepApi.post(
                    '/reports/',
                    values
                ).then(response => onCreate(response.data)).catch(_ => {message.error("Error")});
            }}
            handleError={() => message.error('Failed to create new Report')}
            attributes={attributes}
            okText={"Create"}
        />
    );
}


function TemplateSelectModal({visible, onCancel, onSelect}) {
    const dataSourceCtor = useCallback((q) => {
        return {
            async values(page, limit) {
                return RepApi.get(
                    '/templates/runnable/',
                    {params: {page, limit, q}}
                ).then(
                    ({data}) => repApiAdaptedPageData(data)
                );
            }
        }
    }, []);

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={"Select Template"}
            destroyOnClose={true}
            width={'50vw'}
            footer={null}
        >
            <WithManagedDataSource dataSourceCtor={dataSourceCtor}>
                {props => (
                    <SelectIdTableWithSearch
                        {...props}
                        onSelect={onSelect}
                        tableProps={{
                            extractId: r => r,
                            dataColumns: [{
                                title: 'Title',
                                dataIndex: 'title',
                            }]
                        }}
                    />
                )}
            </WithManagedDataSource>
        </Modal>
    );
}

function ParametersFormList() {
    const form = Form.useFormInstance();
    const schema = form.getFieldValue(["template", "schema"]) ?? {};
    const uiSchema = form.getFieldValue(["template", "ui_schema"]) ?? {};
    const parameters = form.getFieldValue(["template", "parameters"]);
    const setParameters = x => form.setFieldValue(["template", "parameters"], x);

    return (
        <Collapse defaultActiveKey={"parameters"}>
            <Collapse.Panel header={"Parameters"} key={"parameters"}>
                <RJSForm
                    validator={validator}
                    schema={schema}
                    uiSchema={uiSchema}
                    formData={parameters}
                    onChange={e => setParameters(e.formData)}
                    children={true}
                    tagName="div"
                />
            </Collapse.Panel>
        </Collapse>
    );
}
