export function RuleSingleValue(isValid, rejectMsg) {
    return {
        validator: (_, value) => {
            if (isValid(value)) {
                return Promise.resolve();
            }
            return Promise.reject(rejectMsg);
        }
    }
}

export const RuleRequired = subject => ({
    required: true,
    message: `${subject} is required`
});
