import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {CommonLayout} from "./layouts";
import {Templates, Reports, Template, Report} from "./pages"
import {CodeOutlined, DatabaseOutlined} from '@ant-design/icons';

function App() {
    return (
        <Switch>
            <Route path={'/'} exact render={_ => <Redirect to={'/templates'}/>}/>

            <Route exact path={'/templates'}>
                <ReportingCommonLayout>
                    <Templates/>
                </ReportingCommonLayout>
            </Route>

            <Route
                path={'/templates/:templateId'}
                render={(routeProps) => (
                    <ReportingCommonLayout>
                        <Template id={routeProps.match.params.templateId}/>
                    </ReportingCommonLayout>
                )}
            />

            <Route
                path={'/reports/:reportId'}
                render={(routeProps) => (
                    <ReportingCommonLayout>
                        <Report id={routeProps.match.params.reportId}/>
                    </ReportingCommonLayout>
                )}
            />

            <Route path={'/reports'}>
                <ReportingCommonLayout>
                    <Reports/>
                </ReportingCommonLayout>
            </Route>
        </Switch>
    );
}

function ReportingCommonLayout({children}) {
    const siderProps = {
        options: [
            {
                key: 'reports',
                name: 'Reports',
                link: '/reports',
                icon: <DatabaseOutlined/>
            },
            {
                key: 'templates',
                name: 'Templates',
                link: '/templates',
                icon: <CodeOutlined/>
            }
        ]
    };

    return (
        <CommonLayout siderProps={siderProps}>
            {children}
        </CommonLayout>
    );
}

export default App;
