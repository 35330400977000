import {FullHeightCard} from "../../components/cards";
import React, {useEffect, useState} from "react";
import RepApi from "../../services/RepApi";
import {Descriptions, Divider, message, Space, Spin, Tabs, Button} from "antd";
import {useHistory} from "react-router-dom";
import {ArrowLeftOutlinedButton, LinkButton, LinkTextButton} from "../../components/buttons/SimpleButtons";
import {JsonEditor} from "jsoneditor-react";
import {DownloadButton} from "../../components/buttons/DownloadButton";
import {DownloadOutlined} from "@ant-design/icons";
import {
    CreateReportModal,
    extractedReportAttributes,
    mapApiTemplateModelToCreateModel
} from "../reports/CreateReportModal";
import axios from "axios";
import * as XLSX from 'xlsx';
import {AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


function useReportData(id) {
    const [data, setData] = useState(null);
    useEffect(() => {
        RepApi.get(`/reports/${id}`).then(({data}) => setData(data));
    }, [id]);
    return data;
}

export function Report({id}) {
    const data = useReportData(id);
    const history = useHistory();
    return (
        <FullHeightCard
            title={(
                <Space>
                    <ArrowLeftOutlinedButton
                        type={"circle"}
                        style={{marginRight: '8px'}}
                        size={"small"}
                        onClick={_ => history.push("/reports")}
                    />
                    Report {data?.id}
                </Space>
            )}
            extra={data && (
                <ReRun data={data}/>
            )}
        >
            <Spin spinning={data == null} style={{height: '100%'}}>
                <Descriptions column={1}>
                    <Descriptions.Item label={"Title"}>
                        {data?.title}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Created At"}>
                        {data?.created_at}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Status"}>
                        {data?.status}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Template"}>
                        <a href={`/templates/${data?.template}`}>View</a>
                    </Descriptions.Item>
                </Descriptions>
                {data?.status === "completed" && (
                    <DownloadButton url={data?.output} title="Download Report" type="primary"/>
                )}
                <Divider/>
                {data?.parameters && data?.id && (
                    <JsonEditor key={data.id} name="parameters" value={data?.parameters ?? {}} mode={"view"}/>
                )}
                <Divider/>
                {data?.status === "completed" && (
                    <Preview data={data}/>
                )}
            </Spin>
        </FullHeightCard>
    );
}


function Preview({data}) {
    const [tabs, setTabs] = useState([]);
    const [parsedTableReport, setParsedTableReport] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handlePreviewClick = _ => {
        setIsLoading(true);
        axios.get(data.output, {'responseType': 'arraybuffer', onDownloadProgress: console.log})
            .then(response => {
                const workbook = XLSX.read(response.data);
                const parsedReport = {};
                workbook.SheetNames.forEach(sheetName => {
                    parsedReport[sheetName] = {};
                    const sheet = workbook.Sheets[sheetName];
                    const range = XLSX.utils.decode_range(sheet['!ref']);

                    const columnDefs = [];
                    for(let column = range.s.c; column <= range.e.c; ++column) {
                        const cellRef = XLSX.utils.encode_cell({ c: column, r: 0 });
                        columnDefs.push({field: sheet[cellRef].w, filter: true, sortable: true, resizable: true});
                    }

                    const rowData = [];
                    for(let row = range.s.r + 1; row <= range.e.r; ++row) {
                        const item = {};
                        for(let column = range.s.c; column <= range.e.c; ++column) {
                            const headerRef = XLSX.utils.encode_cell({ c: column, r: 0 });
                            const cellRef = XLSX.utils.encode_cell({ c: column, r: row });
                            const headerName = sheet[headerRef]?.w;
                            const cellValue = sheet[cellRef]?.w;
                            item[headerName] = cellValue;
                        }
                        rowData.push(item);
                    }
                    parsedReport[sheetName] = {columnDefs, rowData};
                });
                setParsedTableReport(parsedReport);
                setTabs([...workbook.SheetNames]);
            })
            .catch((e) => {
                console.log(e);
                message.error('Failed to download');
            }).finally(() => {
                setIsLoading(false)
            }
        )
    }

    return (
        <>
            <FullHeightCard
                title="Preview"
                extra={<LinkTextButton text="Load" onClick={handlePreviewClick}/>}
            >
                <div id="width-source" style={{height: '100%', width: '100%'}}>
                    <Spin spinning={isLoading}>
                        {tabs.length > 0 && (
                            <Tabs>
                                {tabs.map(x => (
                                    <Tabs.TabPane tab={x} key={x}>
                                        <PreviewSheet
                                            data={parsedTableReport[x]}
                                            width={document.getElementById("width-source").clientWidth}
                                        />
                                    </Tabs.TabPane>
                                ))}
                            </Tabs>
                        )}
                    </Spin>
                </div>
            </FullHeightCard>
        </>
    )
}


function PreviewSheet({data, width=600, height=400}) {
    return (
        <div className="ag-theme-alpine" style={{height: height, width: width}}>
            <AgGridReact
                rowData={data.rowData}
                columnDefs={data.columnDefs}>
            </AgGridReact>
        </div>
    );
}


function ReRun({data}) {
    const [modalVisible, setModalVisible] = useState(false);
    const history = useHistory();

    const goToReportPage = id => {
        history.push(`/reports/${id}`);
    }

    return (
        <>
            <LinkButton onClick={_ => setModalVisible(true)}>
                Re-run report
            </LinkButton>
            <CreateReportModal
                visible={modalVisible}
                updateVisibility={setModalVisible}
                onCreate={data => goToReportPage(data.id)}
                attributes={new Promise((resolve, reject) => {
                    RepApi.get(
                        `/templates/${data.template}`
                    ).then(response => {
                        resolve(extractedReportAttributes.extract({
                            template: {
                                ...mapApiTemplateModelToCreateModel(response.data),
                                parameters: data.parameters
                            }
                        }));
                    }).catch(reject)
                })}
            />
        </>
    );
}