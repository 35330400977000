import {Tooltip} from "antd";
import {ClearOutlinedButton, SelectOutlinedButton} from "./buttons";
import React, {useState} from "react";
import {Abbreviation} from "./data-renders";

export function ReturnValue({value, map = v => v}) {
    return map(value);
}


export function AnonymousComponent({children, ...props}) {
    return children(props);
}


export function BaseAssignClear({modalRender, assignTitle, clearTitle, ...rest}) {
    const [modalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    }

    const handleSelect = entity => {
        rest.onChange(entity);
        closeModal();
    }

    const handleClear = () => {
        rest.onChange(null);
    }

    return (
        <>
            {modalRender({
                visible: modalVisible, closeModal, handleSelect
            })}
            <ClickToSelect
                value={rest.value}
                assignProps={{
                    title: assignTitle,
                    onClick: showModal
                }}
                clearProps={{
                    title: clearTitle,
                    onClick: handleClear
                }}
            />
        </>
    );
}

export function ClickToSelect({value, assignProps, clearProps}) {
    return (
        <span>
            {value}
            <Tooltip title={assignProps.title}>
                <SelectOutlinedButton onClick={assignProps.onClick}/>
            </Tooltip>
            {value && (
                <Tooltip title={clearProps.title}>
                    <ClearOutlinedButton onClick={clearProps.onClick} style={{marginLeft: '-8px'}}/>
                </Tooltip>
            )}
        </span>
    );
}


export function LabelWithCount({value, renderValue = v => <Abbreviation value={v}/>, count}) {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            {renderValue(value)}
            <span style={{color:"grey"}}>({count})</span>
        </div>
    );
}
