import {ManualPagedTable} from "../../components/tables";
import React, {useState} from "react";
import {Tag, Button} from "antd";
import {LinkButton} from "../../components/buttons";
import {useHistory} from "react-router-dom";
import {
    CreateReportModal,
    extractedReportAttributes,
    mapApiTemplateModelToCreateModel
} from "../reports/CreateReportModal";
import RepApi from "../../services/RepApi";
import {CaretRightOutlined} from "@ant-design/icons";

export function TemplatesTable({asyncDataSource}) {
    const history = useHistory();
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Data Sources',
            dataIndex: 'datasources',
            render: value => value.map(x => (
                <Tag key={x.id}>
                    {x.name}
                </Tag>
            ))
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <FastReportRun id={id}/>
                    <LinkButton
                        onClick={_ => history.push(`/templates/${id}`)}
                    >
                        View
                    </LinkButton>
                </div>
            )
        },
    ];
    return (
        <ManualPagedTable
            id={"templates-table"}
            asyncDataSource={asyncDataSource}
            columns={columns}
            size={"small"}
            rowKey={x => x.id}
        />
    );
}

function FastReportRun({id}) {
    const [modalVisible, setModalVisible] = useState(false);
    const history = useHistory();

    const goToReportPage = id => {
        history.push(`/reports/${id}`);
    }

    return (
        <>
                <Button type="primary" 
                    icon={<CaretRightOutlined />}
                onClick={_ => setModalVisible(true)}>Run report</Button>

                <CreateReportModal
                    attributes={new Promise((resolve, reject) => {
                        RepApi.get(
                            `/templates/${id}`
                        ).then(response => {
                            resolve(extractedReportAttributes.extract({
                                title: 'Untitled Report',
                                template: mapApiTemplateModelToCreateModel(response.data)
                            }));
                        }).catch(reject)
                    })}
                    visible={modalVisible}
                    updateVisibility={setModalVisible}
                    onCreate={data => goToReportPage(data.id)}
                />
        </>
    );
}