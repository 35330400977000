import React from "react";
import {Input} from "antd";
import {FilterableFormItem} from "./generic-entity-form/GenericEntityForm";


export class ExtractedAttribute {
    extract(model) {
        return [];
    }
}


export class AnonymousExtractedAttribute extends ExtractedAttribute {
    constructor(handle) {
        super();
        this._handle = handle;
    }

    extract(model) {
        return this._handle(model);
    }
}


export class PlainExtractedAttribute extends AnonymousExtractedAttribute {
    constructor(key, rest=key=>({})) {
        super(model => [{
            path: key,
            value: model[key],
            toRequestValues: values => ({[key]: values[key]}),
            ...rest(key),
        }]);
    }
}


export class ComposedExtractedAttribute extends ExtractedAttribute {
    constructor(origins) {
        super();
        this._origins = origins;
    }

    extract(model) {
        return this._origins.flatMap(o => o.extract(model));
    }
}


export class SkipParsingIfFieldIsNull extends ExtractedAttribute {
    constructor(origin, fieldToCheck) {
        super();
        this._origin = origin;
        this._fieldToCheck = fieldToCheck;
    }

    extract(model) {
        if (model[this._fieldToCheck] == null) return [];
        return this._origin.extract(model);
    }
}


export class ExtractedText extends AnonymousExtractedAttribute {
    constructor(path, label, rest = {rules: [], readonly: false}) {
        super(model => [{
            path,
            value: model[path],
            readonly: rest.readonly,
            toFormItem: () => (
                <FilterableFormItem key={path} name={path} label={label} rules={rest.rules ?? []}>
                    <Input/>
                </FilterableFormItem>
            )
        }]);
    }
}
