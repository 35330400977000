import {ManualPagedTable} from "../../components/tables";
import React from "react";
import {LinkButton} from "../../components/buttons";

export function ReportsTable({asyncDataSource, onViewClick}) {
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
        },
        {
            title: 'Created at',
            dataIndex: 'created_at',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id) => (
                <LinkButton
                    onClick={_ => onViewClick(id)}
                >
                    View
                </LinkButton>
            )
        },
    ];
    return (
        <ManualPagedTable
            id={"reports-table"}
            asyncDataSource={asyncDataSource}
            columns={columns}
            size={"small"}
            rowKey={x => x.id}
        />
    );
}