import FileDownload from "js-file-download"
import {useState} from "react";
import {Button, message, Spin} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import axios from "axios";


const file_download = function(data, filename, mime, bom) {
    var blobData = (typeof bom !== 'undefined') ? [bom, data] : [data]
    var blob = new Blob(blobData, {type: mime || 'application/octet-stream'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename);
    }
    else {
        var blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);

        if (mime == 'text/html') {
            window.open(blobURL, "_blank");
        } else {
            var tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', filename);

            // Safari thinks _blank anchor are pop ups. We only want to set _blank
            // target if the browser does not support the HTML5 download attribute.
            // This allows you to download files in desktop safari if pop up blocking
            // is enabled.
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
        }

        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
            if (mime != 'text/html') {
                document.body.removeChild(tempLink);
            }
            window.URL.revokeObjectURL(blobURL);
        }, 200)
    }
}

export function DownloadButton({title, url, filename, ...rest}) {
    const [loading, setLoading] = useState(false);

    const handleDownloadClick = () => {
        setLoading(true);
        axios.get(url, {'responseType': 'blob'})
            .then(response => {
                let mime_type = 'application/octet-stream';
                if (url.toLowerCase().endsWith('.html')) {
                    mime_type = 'text/html'
                }
                file_download(response.data, filename ?? /[^\/]+$/.exec(url)[0], mime_type);
                // Doesn't work properly for HTML reports
                // FileDownload(response.data, filename ?? /[^\/]+$/.exec(url)[0]);
            })
            .catch((e) => {
                console.log(e);
                message.error('Failed to download');
            })
            .finally(() => setLoading(false));
    }

    return (
        <Spin spinning={loading}>
            <Button type="link" onClick={handleDownloadClick} {...rest}><DownloadOutlined/> {title ?? 'Download'}</Button>
        </Spin>
    );
}