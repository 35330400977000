import {ApiBase} from "./base/api/ApiBase";
import {API_URL} from "../constants";
import {Auth} from "@digatex/digatex-ui-lib"
import axios from "axios";
import {message} from "antd";

axios.interceptors.request.use((config) => {
    if (Auth.isAuthenticated()) {
        const cb = () => {
            config.headers = { ...config.headers, ...Auth.headers()	}
            return Promise.resolve(config);
        };
        return Auth.updateToken(cb);
    }
});

axios.interceptors.response.use(
    response => response,
    error => {
        const status = error?.response?.status;
        if (status && (status >= 400 && status <= 599)) {
            const data = error.response.data;
            if (data.message != null) {
                message.error(data.message);
            }
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

class RepApi extends ApiBase {
    constructor() {
        super(API_URL, config => {
            return {headers: Auth.headers(), ...config}
        });
    }
}

export default new RepApi();
