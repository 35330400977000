import {Select, Spin} from "antd";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {ApiBase} from "../../../services/base/api/ApiBase";
import {Auth} from "@digatex/digatex-ui-lib";
import debounce from 'lodash/debounce';


export function RemoteSelect({baseUrl, endpoint, labelKey = 'label', valueKey = 'value', debounceTimeout = 800, onSelect}) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const fetchOptions = useCallback(text => {
        return new Promise((resolve, reject) => {
            return new ApiBase(baseUrl, config => {
                return {headers: Auth.headers(), ...config}
            })
                .post(endpoint, {}, {params: {q: text}})
                .then(response => response.data.results.map(item => ({'label': item[labelKey], 'value': item[valueKey]})))
                .then(resolve)
                .catch(reject)
        });
    }, []);

    const debounceFetcher = useMemo(() => {
        const loadOptions = text => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(text).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select
            onSearch={debounceFetcher}
            onSelect={onSelect}
            showSearch={true}
            filterOption={false}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
        />
    );
}
