import React, {useState} from 'react';
import {FullHeightCard} from "../../components/cards";
import {LinkTextButton} from "../../components/buttons";
import {CreateReportModal, emptyReportAttributes} from "./CreateReportModal";
import {useHistory} from "react-router-dom";
import {ReportsTable} from "./ReportsTable";
import RepApi from "../../services/RepApi";
import {repApiAdaptedPageData} from "../../utils";
import {Button} from 'antd';


export function Reports() {
    const [creatingModalVisible, setCreatingModalVisible] = useState(false);
    const history = useHistory();
    const [reportsSource] = useState({
        values: (page, limit) => {
            return new Promise((resolve, reject) => {
               RepApi.get('/reports', {params: {page, limit}}).then(({data}) => {
                   resolve(repApiAdaptedPageData(data));
               }).catch(reject);
            });
        }
    });

    const goToReportPage = id => {
        history.push(`/reports/${id}`);
    }

    const handleNewReportClick = () => {
        setCreatingModalVisible(true);
    }

    return (
        <>
            <FullHeightCard
                title={<div style={{display: 'flex'}}>
                    Reports
                    <Button style={{margin: 'auto'}} 
                     onClick={handleNewReportClick} type="primary">Create new Report</Button>
                </div>}
            >
                <ReportsTable
                    asyncDataSource={reportsSource}
                    onViewClick={goToReportPage}
                />
            </FullHeightCard>
            <CreateReportModal
                attributes={emptyReportAttributes}
                visible={creatingModalVisible}
                updateVisibility={setCreatingModalVisible}
                onCreate={data => goToReportPage(data.id)}
            />
        </>
    );
}
