import {empty} from "../../utils";
import {GenericEntityFormModal} from "../../components/modals";
import RepApi from "../../services/RepApi";
import {Input, message, Select, Space, Tooltip} from "antd";
import {
    AnonymousExtractedAttribute,
    ComposedExtractedAttribute,
    ExtractedText,
    FilterableFormItem,
    RuleRequired,
    RuleSingleValue
} from "../../components/forms";
import {AnonymousComponent} from "../../components/Misc";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {JsonEditor} from "jsoneditor-react";
import ace from 'brace';

export const emptyTemplateData = {
    title: '',
    description: '',
    datasources: [],
    parameters: {},
    params_schema: {},
    params_ui_schema: {},
    notebook: null
};


const attributes = (data, notebookProps) => {
    return async () => {
        const requiredRules = subject => [RuleRequired(subject)];
        return RepApi.get('/datasources/').then(response => {
            const knownDataSources = response.data;
            return new ComposedExtractedAttribute([
                new ExtractedText('title', 'Title', {rules: requiredRules('Title')}),
                new ExtractedText('description', 'Description', {rules: requiredRules('Description')}),
                new AnonymousExtractedAttribute(model => [{
                    path: 'datasources',
                    value: model.datasources,
                    toFormItem: () => (
                        <FilterableFormItem
                            required={true}
                            key="datasources"
                            label="Data Sources"
                            name="datasources"
                            rules={[
                                RuleSingleValue(value => value && value.length > 0, 'Data Sources cannot be empty')
                            ]}
                        >
                            <Select
                                mode="tags"
                                style={{width: '100%'}}
                                options={knownDataSources.map(x => ({
                                    label: x.name,
                                    value: x.id
                                }))}
                            />
                        </FilterableFormItem>
                    )
                }]),
                new AnonymousExtractedAttribute(model => [{
                    path: 'notebook',
                    value: model.notebook,
                    toRequestValues: values => ({notebook: values.notebook.file}),
                    toFormItem: () => (
                        <FilterableFormItem
                            required={notebookProps.required}
                            key="notebook"
                            label={notebookProps.tooltip ? (
                                <Space>
                                    <span>Notebook</span>
                                    <Tooltip title={notebookProps.tooltip}>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </Space>
                            ) : "Notebook"}
                            name="notebook"
                            rules={[
                                RuleSingleValue(value => value, 'Notebook cannot be empty')
                            ]}
                        >
                            <AnonymousComponent>
                                {({value, onChange}) => (
                                    <Input
                                        type={"file"}
                                        value={value?.name}
                                        onChange={e => {
                                            return onChange({
                                                name: e.target.value,
                                                file: (e.target.files ?? [])[0]
                                            });
                                        }}
                                    />
                                )}
                            </AnonymousComponent>
                        </FilterableFormItem>
                    )
                }]),
                new AnonymousExtractedAttribute(model => [{
                    path: 'params_schema',
                    value: model.params_schema,
                    toRequestValues: values => ({
                        params_schema: {
                            ...values.params_schema,
                            toString: () => JSON.stringify(values.params_schema)
                        }
                    }),
                    toFormItem: () => (
                        <FilterableFormItem
                            label={(
                                <Space>
                                    <span>Parameters Schema</span>
                                    <Tooltip
                                        title={(
                                            <div>
                                                <a href={"https://rjsf-team.github.io/react-jsonschema-form/docs/usage/objects"}>
                                                    Docs
                                                </a>
                                            </div>
                                        )}
                                    >
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            )}
                            name={"params_schema"}
                        >
                            <EditParameters/>
                        </FilterableFormItem>
                    )
                }]),
                new AnonymousExtractedAttribute(model => [{
                    path: 'params_ui_schema',
                    value: model.params_ui_schema,
                    toRequestValues: values => ({
                        params_ui_schema: {
                            ...values.params_ui_schema,
                            toString: () => JSON.stringify(values.params_ui_schema)
                        }
                    }),
                    toFormItem: () => (
                        <FilterableFormItem
                            label={(
                                <Space>
                                    <span>Parameters UI Schema</span>
                                    <Tooltip
                                        title={(
                                            <div>
                                                <a href={"https://rjsf-team.github.io/react-jsonschema-form/docs/api-reference/uiSchema"}>
                                                    Docs
                                                </a>
                                            </div>
                                        )}
                                    >
                                        <QuestionCircleOutlined/>
                                    </Tooltip>
                                </Space>
                            )}
                            name={"params_ui_schema"}
                        >
                            <EditParameters/>
                        </FilterableFormItem>
                    )
                }])
            ]).extract(data);
        });
    }
};


export function EditTemplateModal({data, notebookProps, visible, updateVisibility, onOk=empty, okText, title}) {
    const onFinish = async values => {
        const form = new FormData();
        Object.keys(values).forEach(key => {
            const value = values[key];
            if (Array.isArray(value)) {
                value.forEach(x => {
                    form.append(key, x.toString())
                });
            } else {
                form.append(key, values[key]);
            }
        });
        return onOk(form, values);
    }

    return (
        <GenericEntityFormModal
            visible={visible}
            updateVisibility={updateVisibility}
            title={title}
            onFinish={onFinish}
            handleError={() => message.error('Failed to create new Template')}
            attributes={attributes(data, notebookProps)}
            okText={okText}
            formRestProps={{
                lenOfItemsToActivateFilter: 10
            }}
        />
    );
}


function EditParameters({value, onChange}) {
    const props = {
        mode: 'code',
        ace: ace,
        htmlElementProps: {style: {height: '40vh'}}
    };

    return (
        <JsonEditor
            {...props}
            value={value}
            onChange={onChange}
        />
    );
}
