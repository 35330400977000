import React, {useState} from 'react';
import {FullHeightCard} from "../../components/cards";
import RepApi from "../../services/RepApi";
import {EditTemplateModal, emptyTemplateData} from "./EditTemplateModal";
import {TemplatesTable} from "./TemplatesTable";
import {LinkTextButton} from "../../components/buttons";
import {useHistory} from "react-router-dom";
import {message} from "antd";
import {repApiAdaptedPageData} from "../../utils";


export function Templates() {
    const history = useHistory();
    const [creatingModalVisible, setCreatingModalVisible] = useState(false);
    const [templatesSource] = useState({
        values: async (page, limit) => {
            return new Promise((resolve, reject) => {
                RepApi.get('/templates/', {params: {page, limit}}).then(
                    templatesResponse => {
                        RepApi.get('/datasources/').then(datasourcesResponse => {
                            const templates = repApiAdaptedPageData(templatesResponse.data);
                            const datasources = datasourcesResponse.data;
                            datasources.findById = function (id) {
                                return this.find(x => x.id === id);
                            }
                            resolve(templates.map(x => {
                                    if (x == null) return x;
                                    return {
                                        ...x,
                                        datasources: x.datasources.map((dsId, idx) => {
                                            const dsData = datasources.findById(dsId) ?? {
                                                name: 'UNKNOWN',
                                                id: `UNKNOWN_${idx}`
                                            };
                                            return {...dsData};
                                        })
                                    }
                                })
                            );
                        }).catch(reject);
                    }
                ).catch(reject)
            });
        }
    });

    const handleNewTemplateClick = _ => {
        setCreatingModalVisible(true);
    }

    return (
        <>
            <FullHeightCard
                title={"Report Templates"}
                extra={<LinkTextButton text={"Create new Template"} onClick={handleNewTemplateClick}/>}
            >
                <TemplatesTable asyncDataSource={templatesSource}/>
            </FullHeightCard>
            <EditTemplateModal
                title={'Create Template'}
                notebookProps={{required: true}}
                okText={"Create"}
                data={emptyTemplateData}
                visible={creatingModalVisible}
                updateVisibility={setCreatingModalVisible}
                onOk={form => new Promise((resolve, reject) => {
                    RepApi.post(
                        '/templates/',
                        form,
                        {headers: {'Content-Type': 'multipart/form-data'}}
                    ).then(response => {
                        message.success("Created");
                        history.push(`/templates/${response.data.id}`);
                        resolve(response)
                    }).catch(reject)
                })}
            />
        </>
    )
}
